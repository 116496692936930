import type {
    RequestErrorType,
    AsoebiQuestionnaireType,
    auditType,
    budgetType,
    categoryType,
    CeremonyType,
    EventType,
    exportBudgetType,
    pricingPlanPaymentType,
    submitAsoebiPayloadType,
    TodoType,
    verifyPaymentType,
    submitAsoebiTrackerPayloadType,
    costItemType,
    FloorPlanType,
    EventdayFlowType,
} from "@/types";
import axios from "@/axios/config";
import {
    AddChecklistBody,
    AsoebiAdminType,
    AsoebiFormType,
    BudgetExportResponse,
    ModelResultResponse,
    SaveNewUserBody,
    UserStatusResponse,
    pricingPlanDetailsType,
    runModelBodyType,
    AxiosResponseType,
    AxiosResponseNoDataType,
} from "@/data/types";
import { addMonths } from "date-fns";

const BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

export const run_model = async (body: runModelBodyType): Promise<ModelResultResponse> => {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/calculate`, body);
        return data.data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
};

export const exportBudget = async (body: exportBudgetType): Promise<BudgetExportResponse> => {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/export_budget`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
};

export async function saveNewUser(body: SaveNewUserBody): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/auth/save-new-user`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function checkUserStatus(uid: string): Promise<UserStatusResponse> {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/auth/check-user-status`, { uid });
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function createEvent(body: EventType): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/event/add`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function addChecklist(checklist: AddChecklistBody, eventId: string, ceremony_id: string): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/event/${eventId}/checklist/add`, { checklist, ceremony_id });
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function addMultipleEventChecklist(
    body: {
        tag: { id: string; name: string };
        checklists: TodoType;
    },
    eventId: string,
): Promise<AxiosResponseNoDataType> {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/event/${eventId}/checklist/add-multiple`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function addCategory(body: categoryType, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/category/add/${eventId}`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

type onboardingCeremonyType = {
    ceremonies: Array<{
        id: string;
        name: string;
        location: string | null;
        no_of_guests: number | null;
        date: Date | null;
        checklist?: TodoType;
        show_suggestion?: boolean;
        // image?: string;
    }>;
    details: {
        user?: string | "Groom" | "Bride";
        budgetAmount?: number;
        hasBudget?: boolean;
        days?: number;
    };
};

export async function addCeremony(body: CeremonyType[0] | onboardingCeremonyType, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/event/${eventId}/ceremony/add`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function addEventDayFlowRequest(body: { event_day_flow: EventdayFlowType[0]; ceremony_id: string }, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/event/${eventId}/event-day-flow/add`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function addMultipleCeremonies(ceremonies: CeremonyType | onboardingCeremonyType, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/event/${eventId}/add-multiple-ceremony`, ceremonies);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

type formDetailsType = {
    name: string;
    email: string;
    phoneNumber: string;
    communicationMethod: string;
    vendorRequirement: string;
    vendorPlans: string;
    amountPaid: string;
    vendorDetails: Array<{
        vendorService: string;
        eventLocation: string;
        vendorEstimatedBudget: string;
        eventDate?: Date | null;
    }>;
};

export async function vendorAssistanceRequest(formDetails: formDetailsType) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/vendor-assistance`, { email: formDetails.email });
        await axios.post(`${BASE_URL}/api/v1/vendor-assistance-spreadsheet`, formDetails);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateEventPictureRequest(formData: FormData, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/event/${eventId}/update-event-picture`, formData);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function addBudgetEstimate(budget: budgetType, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/add-budget-estimate/${eventId}`, { budget });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function duplicateEvent(eventDetails: { id: string; name: string; event_date: Date; event_bg: string }) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/event/duplicate/${eventDetails.id}`, eventDetails);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

type defaultLocationType = Array<{ id: number; location: string; no_of_guests: number }>;
export async function addDefaultBudget(budget: defaultLocationType, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/add-default-budget/${eventId}`, budget);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updateAudit(audit: auditType, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/update-audits/${eventId}`, audit);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function sendSignupEmail(name: string, email: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/email/send-signup-email`, { email, name });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function sendPricingPlanEmail(planDetails: pricingPlanDetailsType) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/send-pricing-plan-email`, planDetails);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}
type sendBasicSubscriptionReminderEmailType = {
    name: string;
    email: string;
    plan: string;
    paid_collaborators: string[];
    access_duration: number;
};

export async function sendBasicSubscriptionReminderEmail(body: sendBasicSubscriptionReminderEmailType, eventId: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/send-basic-subscription-ending-notice-email/${eventId}`, body);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function updatePricingPlan(updatePricingPlan: {
    plan: pricingPlanPaymentType;
    monthDuration?: number;
    amount: number;
    max_events: number;
    collaborator_per_events: number;
    eventId: string;
}) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/update-pricing-plan/${updatePricingPlan.eventId}`, updatePricingPlan);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

type tourGuideType =
    | { user_profile: boolean }
    | { event_overview: boolean }
    | { budget_tracker: boolean }
    | { checklist: boolean }
    | { homepage: boolean };

export async function updateTourGuide(result: tourGuideType) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/update-tour-guide`, result);
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function renewCollaboratorAccountRequest(duration: number, email: string, eventId: string) {
    const end_date = addMonths(new Date(), Number(duration));
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/renew-collaborator-account/${eventId}`, { duration, email, end_date });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function onboardCollaborator(user_id: string, event_id: string, inviter_id: string) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/user/${user_id}/onboard-invited-collaborator/${event_id}`, { inviter_id });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function verifyPayment({ reference_id, user_id, event_id, type, amount, actual_amount }: verifyPaymentType) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/payment/${reference_id}`, { user_id, event_id, type, amount, actual_amount });
        return data;
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data.message);
    }
}

export async function onboardUserDetails(
    id: string,
    body:
        | {
                name: string;
                business_name?: string;
                phone_number?: string;
                website?: string;
                tour_guide: {
                    user_profile: boolean;
                    event_overview: boolean;
                    budget_tracker: boolean;
                    checklist: boolean;
                    floor_plan?: boolean;
                    homepage?: boolean;
                };
          }
        | { onboarding_id: string },
) {
    try {
        const data = await axios.post(`${BASE_URL}/api/v1/user/${id}/onboard`, body);
        return data;
    } catch (error: unknown) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}
export type createAsoebiType = AsoebiQuestionnaireType & {
    consentToTransactionPolicy: boolean;
    orderFormType: keyof typeof AsoebiFormType | "";
};
export async function generateQuestionnaireLink(questionnaire_details: createAsoebiType) {
    try {
        return await axios.post(`${BASE_URL}/api/v1/create-asoebi-questionnaire`, questionnaire_details);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function uploadQuestionnaireMedia(formData: FormData, questionnaire_id: string) {
    try {
        return await axios.post(`${BASE_URL}/api/v1/upload-asoebi-questionnaire-images/${questionnaire_id}`, formData);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function uploadProfileImage(formData: FormData, user_id: string) {
    try {
        return await axios.post(`${BASE_URL}/api/v1/upload-profile-image/${user_id}`, formData);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function uploadCeremonyImage(formData: FormData, ceremony_id: string, event_id: string) {
    try {
        return await axios.post(`${BASE_URL}/api/v1/${ceremony_id}/upload-ceremony-image/${event_id}`, formData);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function verifyAsoebiPayment(
    referenceId: string,
    actual_amount: number,
    questionnaire_id: string,
    { name, email, event_name, environment }: { name: string; email: string; event_name: string; environment: string },
) {
    try {
        return await axios.post(`${BASE_URL}/api/v1/verify-asoebi-payment/${referenceId}`, {
            actual_amount,
            questionnaire_id,
            name,
            email,
            event_name,
            environment,
        });
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function submitAsoebiQuestionnaire(payload: submitAsoebiPayloadType | submitAsoebiTrackerPayloadType) {
    try {
        return await axios.post(`${BASE_URL}/api/v1/submit-asoebi-questionnaire`, payload);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function sendMagicLink(email: string) {
    try {
        return await axios.post(`${BASE_URL}/api/v1/send-asoebi-magic-link`, { email });
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function createAdmin(email: string, data?: unknown) {
    try {
        return await axios.post(`${BASE_URL}/api/v1/create-asoebi-admin`, { email, data });
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function verifyMagicLink(token: string): Promise<AxiosResponseType<AsoebiAdminType>> {
    try {
        return await axios.post(`${BASE_URL}/api/v1/verify-asoebi-magic-link`, { token });
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function addVendorRequest(data: costItemType["vendor"]): Promise<AxiosResponseNoDataType> {
    try {
        return await axios.post(`${BASE_URL}/api/v1/add-vendor`, data);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function addCostItemToBudgetCategoryRequest(
    eventId: string,
    category_id: string,
    ceremonyId: string,
): Promise<AxiosResponseType<budgetType["categories"]>> {
    try {
        return await axios.post(`${BASE_URL}/api/v1/event/${eventId}/${category_id}/add-cost-item`, { ceremonyId });
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

type FloorPlanDetailsType = Partial<FloorPlanType[0]> & {
    event_id: string;
    ceremony_id: string;
};

export async function createFloorPlanRequest(event_id: string, floorPlanDetails: FloorPlanDetailsType): Promise<AxiosResponseNoDataType> {
    try {
        return await axios.post(`${BASE_URL}/api/v1/${event_id}/create-floor-plan`, floorPlanDetails);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function uploadFloorPlanElementRequest(
    formData: FormData,
    id: string,
    element_id: string,
): Promise<AxiosResponseType<{ url: string }>> {
    try {
        return await axios.post(`${BASE_URL}/api/v1/floorplan-image-upload/${id}/${element_id}`, formData);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function addFloorplanElementRequest(
    id: string,
    element_id: string,
    event_id: string,
    data: Partial<FloorPlanType[0]["stage"][0]>,
): Promise<AxiosResponseType<{ url: string }>> {
    try {
        return await axios.post(`${BASE_URL}/api/v1/${event_id}/add-floorplan-element/${id}/${element_id}`, data);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function addCustomerToPaystackRequest(data: {
    email: string;
    name: string;
}): Promise<AxiosResponseType<{ url: string }>> {
    try {
        return await axios.post(`${BASE_URL}/api/v1/add-customer-to-paystack`, data);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function subscribeToPlan(data: {
    email: string;
    startDate: string;
}): Promise<AxiosResponseType<{ url: string }>> {
    try {
        return await axios.post(`${BASE_URL}/api/v1/subscribe-to-planaday-default-plan`, data);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}

export async function cancelSubscriptionRequest(
    data: {
        subscription_code: string;
        subscription_token: string;
    },
    userId: string,
): Promise<AxiosResponseType<{ url: string }>> {
    try {
        return await axios.post(`${BASE_URL}/api/v1/cancel-subscription/${userId}`, data);
    } catch (error) {
        const _error = error as RequestErrorType;
        throw new Error(_error.response.data?.message);
    }
}
