import { useQuery } from "@tanstack/react-query";

import { getUserDetails } from "@/axios/get-request";
import { useEvent } from "./useEvent";

export default function useUser() {
    const { selectedEventId } = useEvent();
    const { status, data, error } = useQuery({
        queryKey: ["get_user"], // fetch user details for a specific event.
        queryFn: () => {
            return getUserDetails();
        },
    });

    return {
        data,
        status,
        error,
        selectedEventId,
    };
}
