import { AxiosResponse } from "axios";

export enum EventCategory {
    STANDARD = "standard",
    MID_LUXURY = "midluxury",
    LUXURY = "luxury",
}

export enum ItemCategory {
    PLANNING_AND_DECOR = "planning_and_decoration",
    FOOD_AND_DRINK = "food_drink",
    WEDDING_APPAREL = "wedding_apparel",
    ENTERTAINMENT = "entertainment_and_media",
    RENTALS = "rentals",
    SECURITY_STAFFING = "security_and_staffing",
    STATIONARIES = "stationaries",
    MISCELLANOUS = "miscellaneous",
}

export enum PriceType {
    UNIT = "unit",
    PER_PERSON = "per_person",
    PER_PLATE = "per_plate",
}

export enum Location {
    MAINLAND = "mainland",
    ISLAND = "island",
    PORT_HARCOURT = "Port-Harcourt",
    OTHER = "Other",
    ABUJA = "Abuja",
    IBADAN = "Ibadan",
}

export interface ModelInput {
    location: Location;
    no_of_guests: number;
}

export interface ModelResultCost {
    cost_standard: number;
    cost_mid_luxury: number;
    cost_luxurious: number;
}

export interface ModelResultItemBreakdown extends ModelResultCost {
    item: string;
    item_code: string;
    category: ItemCategory;
    checked: boolean;
}

export interface ModelResultSummary {
    standard_total: number;
    mid_luxury_total: number;
    luxurious_total: number;
}

export interface ModelResult {
    breakdown: ModelResultItemBreakdown[];
    summary: ModelResultSummary;
}
export interface ModelResultResponse extends ModelResult {
    status: boolean;
    message: string;
}

export type LocationAndGuests = {
    location: Location;
    no_of_guests: number;
};

export type BudgetExportResponse = {
    data: {
        message: string;
    };
    status: number;
};

export type UserStatusResponse = {
	data: {
		message: string;
		status: boolean;
		result: boolean;
	};
};

export type AddChecklistResponse = {
    data: {
        message: string;
    };
    status: number;
};

export type AddChecklistBody = {
    name: string;
    note: string | null;
    due: Date | null;
    isDone: boolean;
    priority?: string;
    ceremony: { name: string; id: string };
    id: string;
};

export type SaveNewUserBody = {
    uid: string;
    email: string | null;
    photo_url: string | null;
    phone_number: string | null;
    display_name: string | null;
    email_verified: boolean;
    user_type: "planner" | "celebrant" | "";
};

export type budgetForm = {
    user: string;
    budget: number;
    hasBudget: boolean;
    days: number;
};

export enum ScreenCategory {
    BUDGET_FORM_SCREEN = "Budget Form Screen",
    CALCULATOR_ANSWER_SCREEN = "Calculator Answer Screen",
}

export enum Action {
    FACEBOOK_SHARE = "Facebook share",
    WHATSAPP_SHARE = "Whatsapp share",
    TWITTER_SHARE = "Twitter share",
    GROOM_COUNT = "Groom selection count",
    BRIDE_COUNT = "Bride selection count",
    OTHER_GENDER_COUNT = "Other selection count",
    NO_BUDGET_COUNT = "No budget selection count",
    HAS_BUDGET_COUNT = "Has budget selection count",
    TOTAL_BUDGET_COUNT = "Total Number Of Budget Created",
    TOTAL_SUBSCRIBED_TO_UPDATES_COUNT = "Total Number Of User subscribed to updates",
    TOTAL_NOT_SUBSCRIBED_TO_UPDATES_COUNT = "Total Number Of User not subscribed to updates",
    HELP_FIND_VENDOR = "Help find vendor",
}

export enum Label {
    BUTTON = "Button",
    CUSTOM_LOCATION = "Custom location",
    CHECKBOX = "Checkbox",
}

export enum Pricing {
	DefaultPlan = 15000,
    Test_account_validity = 50
}

export enum PlanType {
    CUSTOM = "CUSTOM",
    PREMIUM = "PREMIUM",
    ESSENTIAL = "ESSENTIAL",
    BASIC = "BASIC",
    FREE = "FREE",
}

export type InputData = {
    id: number;
    location: string;
    no_of_guests: number;
};

export type breakdownType = {
    item_code?: string;
    price?: number;
    item?: ModelResultItemBreakdown["item"];
    item_category?: string;
    checked?: boolean;
    sum_of_guests?: number;
    total_cost?: number;
    duration?: number;
    budget?: budgetForm["budget"] | string;
};

export type pricingPlanDetailsType = {
    name: string;
    email: string;
    plan_benefit: string;
    plan: string;
    feature_1: string;
    feature_2: string;
    feature_3: string;
};

export enum AuditType {
    general = "general",
    checklist = "checklist",
    budget_tracker = "budget_tracker",
}

export enum BudgetCategoriesType {
    "standard" = "standard",
    "mid_luxury" = "mid_luxury",
    "luxurious" = "luxurious",
}

export type paystackReferenceType = {
    reference: string;
    trans: string;
    status: string;
    message: string;
    transaction: string;
    trxref: string;
    redirecturl: string;
};

export type initializePaymentType = (callback?: ((reference: paystackReferenceType) => void) | undefined, onClose?: (() => void) | undefined) => void;

export type runModelBodyType = Array<{
    id: number;
    location: string;
    no_of_guests: number;
}>;

export type AsoebiAdminType = {
    magiclink: string;
    name: string;
    magiclink_id: string;
    hasSignup: boolean;
    email: string;
    questionnaire_ids: string[];
    vendor_id: string;
    vendor_slug: string;
};

export enum RequestType {
    Success = "Success",
    Error = "Error",
}

export type AsoebiOptionType = Array<{ option: string; price: string; max_stock_qty: number; stock_bought_qty: number }>;

export enum AsoebiFormType {
    orderform = "orderform",
    tracker = "tracker",
}

export enum FloorPlanObjectList {
	sofa__table_chairs__floor_plan_element = "sofa__table_chairs__floor_plan_element",
	single_chair__table_chairs__floor_plan_element = "single_chair__table_chairs__floor_plan_element",
	double_chair__table_chairs__floor_plan_element = "double_chair__table_chairs__floor_plan_element",
	round_table__table_chairs__floor_plan_element = "round_table__table_chairs__floor_plan_element",
	table_of_10__table_chairs__floor_plan_element = "table_of_10__table_chairs__floor_plan_element",
	table_of_3__table_chairs__floor_plan_element = "table_of_3__table_chairs__floor_plan_element",
	table_of_8__table_chairs__floor_plan_element = "table_of_8__table_chairs__floor_plan_element",
	square_table__table_chairs__floor_plan_element = "square_table__table_chairs__floor_plan_element",
	rectangle__floor_plan_element = "rectangle__floor_plan_element",
	rectangle_3__floor_plan_element = "rectangle_3__floor_plan_element",
	rectangle_6__floor_plan_element = "rectangle_6__floor_plan_element",
	rectangle_8__floor_plan_element = "rectangle_8__floor_plan_element",
	rectangle_10__floor_plan_element = "rectangle_10__floor_plan_element",
	entertainment__floor_plan_element = "entertainment__floor_plan_element",
	food_drinks__floor_plan_element = "food_drinks__floor_plan_element",
	stage__floor_plan_element = "stage__floor_plan_element",
	gifts__floor_plan_element = "gifts__floor_plan_element",
	shapes__floor_plan_element = "shapes__floor_plan_element",
	stage__text = "stage__text",
	lines_element = "lines_element",
	regular__dimensions = "regular__dimensions",
	stage_wall = "stage_wall",
	table_chairs__category = "table_chairs__category",
	entertainment__category = "entertainment__category",
	food_drinks__category = "food_drinks__category",
	stage__category = "stage__category",
	doors__category = "doors__category",
	gifts__category = "gifts__category",
	shapes__category = "shapes__category",
	entertainment__band = "entertainment__band",
	entertainment__dj = "entertainment__dj",
	entertainment__equipment = "entertainment__equipment",
	entertainment__fill_dance_floor = "entertainment__fill_dance_floor",
	buffet__floor_plan_element = "buffet__floor_plan_element",
	cake_table__floor_plan_element = "cake_table__floor_plan_element",
	cocktails__floor_plan_element = "cocktails__floor_plan_element",
	drinks_bar__floor_plan_element = "drinks_bar__floor_plan_element",
	dessert_table__floor_plan_element = "dessert_table__floor_plan_element",
	catering_station__floor_plan_element = "catering_station__floor_plan_element",
	stage_stairs__floor_plan_element = "stage_stairs__floor_plan_element",
	double_door_open__floor_plan_element = "double_door_open__floor_plan_element",
	double_door_closed__floor_plan_element = "double_door_closed__floor_plan_element",
	single_door_open__floor_plan_element = "single_door_open__floor_plan_element",
	single_door_closed__floor_plan_element = "single_door_closed__floor_plan_element",
	square__floor_plan_element = "square__floor_plan_element",
	circle__floor_plan_element = "circle__floor_plan_element",
	element_with_text = "element_with_text",
	image_element = "image_element",
}

export type FloorPlanObjectListType = keyof typeof FloorPlanObjectList;

export type FloorplanObjectType = {
    x: number;
    y: number;
    id: string;
    width: number;
    height: number;
    fill: string;
    type: FloorPlanObjectListType;
};

export type EventFilterType = "newest" | "asc" | "desc";

export enum CollaboratorPermissionList {
    can_edit = "can_edit",
    view_only = "view_only"
}

export type AxiosResponseType<T> = AxiosResponse<{
    message: string;
    status: number;
    result: T
}>;

export type AxiosResponseNoDataType = AxiosResponse<{
	message: string;
	status: number;
}>;
