import { differenceInDays, format, getDate } from "date-fns";
import { useNavigate } from "react-router-dom";

import image from "@/assets/img/default_profile_img.webp";
import WalletIcon from "@/assets/icon/WalletIcon";
import { formatCurrency } from "@/helper";
import type { costItemType } from "@/types";

interface Props {
    upcomingPayments: costItemType[] | null;
    ceremonyDate?: Date | null;
}

export default function ManageBudgetlist({ upcomingPayments, ceremonyDate }: Props) {
    const navigate = useNavigate();
    return (
        <div className="budget_view item_view">
            <div className="row">
                <h4>
                    <WalletIcon /> Budget
                </h4>
                <button onClick={() => navigate("/dashboard/budget-tracker")}>Manage</button>
            </div>
            <p>Upcoming payments</p>
            <div className="item_group">
                {!upcomingPayments || upcomingPayments?.length === 0 && (
                    <div className="budget">
                        <time>
                            --
                            <br />
                            <br />
                            <br />
                            <span>00</span>
                        </time>
                        <div className="item budget_list_view">
                            <div className="budget_list">
                                <img src={image} alt="vendor image" />
                                <div className="text">
                                    <h5>--</h5>
                                    <p>--</p>
                                </div>
                            </div>
                            <p>--.--</p>
                        </div>
                    </div>
                )}
                {upcomingPayments &&
                    upcomingPayments?.map((item) => {
                        const duePeriod =
                            ceremonyDate && item.balance_due ? differenceInDays(new Date(item.balance_due), new Date()) : "";
                        const balanceDueText =
                            item.balance_due && new Date(item.balance_due) > new Date() ? `Due in ${duePeriod} day` : "Already due";
                        const dateNumber = item.balance_due ? getDate(new Date(item.balance_due)) : "";
                        const day = item.balance_due ? format(new Date(item.balance_due), "eee") : "";
                        const balance = item.actual - item.paid;
                        return (
                            <div key={item.id} className="budget">
                                <time>
                                    {day}
                                    <br />
                                    <br />
                                    <br />
                                    <span>{dateNumber}</span>
                                </time>
                                <div className="item budget_list_view">
                                    <div className="budget_list">
                                        <div className="text">
                                            <h5>{item.name}</h5>
                                            <p>{balanceDueText}</p>
                                        </div>
                                    </div>
                                    <p>{formatCurrency(balance, "")} NGN</p>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
