import type { Identifier, XYCoord } from "dnd-core";
import { useRef, PropsWithChildren } from "react";
import { useDrag, useDrop } from "react-dnd";
import { format } from "date-fns";

import ChartIcon from "@/assets/icon/ChartIcon";
// import GripIcon from "@/assets/icon/GripIcon";
import TrashIcon2 from "@/assets/icon/TrashIcon2";
import CalendarDateIcon from "@/assets/icon/CalendarDateIcon";
import { TodoType } from "@/types";
import "@/styles/checklist_new.scss";
import { PencilIcon } from "lucide-react";

interface Props {
	title: string;
}

const style = {
    border: "1px dashed red",
    cursor: "move",
    opacity: 0,
    backgroundColor: "transparent",
};

enum ModalList {
	add_checklist_task_modal = "add_checklist_task_modal",
	edit_checklist_task_modal = "edit_checklist_task_modal",
	delete_checklist_task_modal = "delete_checklist_task_modal",
}

export function DisplayChecklistStatus({ title }: Props) {
    const status = title.toLowerCase();
    return (
        <div className="display_status">
            {status === "urgent" ? (
                <div className="icon urgent">
                    <span>!</span> {title}
                </div>
            ) : (
                <div className={`icon ${status}`}>
                    <ChartIcon /> {title}
                </div>
            )}
        </div>
    );
}

interface ChecklistItemProps {
	checklist: TodoType[0];
	modalActionHandler: (modalAction: keyof typeof ModalList) => void;
	index: number;
	moveCard: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
	index: number;
	id: string;
	type: string;
}

const ItemTypes = {
    CARD: "card",
};

export default function DraggableCeremonyChecklistItem({
    checklist,
    children,
    index,
    moveCard,
    modalActionHandler,
}: PropsWithChildren<ChecklistItemProps>) {
    const className = checklist.isDone ? "checklist_completed" : "";
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index as number;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            if (moveCard) {
                moveCard(dragIndex, hoverIndex);
            }
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id: checklist.id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const draggingStyles = isDragging ? style : {};
    drag(drop(ref));

    return (
        <div ref={ref} style={draggingStyles} data-handler-id={handlerId} className={`checklist_item ${className}`}>
            {children}
            <div className="checklist_wrapper">
                <div className="checklist_text_view">
                    <h6>{checklist?.name}</h6>
                    <div className="period">
                        {checklist?.due && <CalendarDateIcon />}
                        {checklist?.due ? (
                            <span className="date">{checklist?.due ? `${format(new Date(checklist?.due), "EEE do MMM")}` : "--"}</span>
                        ) : (
                            "--"
                        )}
                        <hr />
                        {!checklist?.isDone && checklist.priority ? (
                            <DisplayChecklistStatus title={checklist.priority as string} />
                        ) : (
                            <div className="no_priority"> No priority</div>
                        )}
                    </div>
                </div>
                <div className="checklist_actions">
                    {!checklist.isDone && (
                        <button onClick={() => modalActionHandler(ModalList.edit_checklist_task_modal)}>
                            <PencilIcon />
                        </button>
                    )}
                    <button onClick={() => modalActionHandler(ModalList.delete_checklist_task_modal)}>
                        <TrashIcon2 />
                    </button>
                    {/* {!checklist.isDone && (
                        <button>
                            <GripIcon />
                        </button>
                    )} */}
                </div>
            </div>
        </div>
    );
}
