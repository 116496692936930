import { useState } from "react";

import NewDashboardLayout from "@/layout/NewDashboardLayout";
import InfoIconFill from "@/assets/icon/InfoIconFill";
import PlusIcon from "@/assets/icon/PlusIcon";
// import ThreeDotsIcon from "@/assets/icon/ThreeDotsIcon";
// import SiteDropdown from "@/components/Dropdown";
import useMyEvents from "@/hooks/useMyEvents";
import Spinner from "@/components/Spinner";
import DefaultView from "@/components/DefaultView";
import InviteCollaboratorModal from "@/components/modals/InviteNewCollaboratorModal";
import { CollaboratorType } from "@/types";
import EditCollaboratorModal from "@/components/modals/EditCollaboratorModal";
import "@/styles/collaborator.scss";
import { toast } from "react-toastify";
// import { toast } from "react-toastify";

// const dropdownAlignment = { alignRight: true };

enum ModalList {
    invite_collaborator_modal = "invite_collaborator_modal",
    remove_collaborator_modal = "remove_collaborator_modal",
    edit_collaborator_modal = "edit_collaborator_modal",
}
type ModalType = `${ModalList}`;

export default function CollaboratorsPage() {
    const { activeEvent, status } = useMyEvents();
    const activeEventData = activeEvent ? activeEvent[0] : null;
    const [modal, setModal] = useState<ModalType | null>(null);
    const [collaboratorDetails] = useState<CollaboratorType | null>(null);
    // setCollaboratorDetails
    function closeModal() {
        setModal(null);
    }

    // function editCollaboratorModal(__collaborator: CollaboratorType) {
    //     setCollaboratorDetails(__collaborator);
    //     setModal(ModalList.edit_collaborator_modal);
    // }

    function inviteCollaborator() {
        if (!activeEventData) {
            return toast.error("Please create an event");
        }
        setModal(ModalList.invite_collaborator_modal);
    }

    return (
        <>
            {modal === ModalList.invite_collaborator_modal && activeEventData && (
                <InviteCollaboratorModal eventName={activeEventData.event_name} onClose={closeModal} />
            )}
            {modal === ModalList.edit_collaborator_modal && activeEventData && collaboratorDetails && (
                <EditCollaboratorModal collaboratorDetails={collaboratorDetails} eventName={activeEventData.event_name} onClose={closeModal} />
            )}
            {modal === ModalList.remove_collaborator_modal && activeEventData && (
                <InviteCollaboratorModal eventName={activeEventData.event_name} onClose={closeModal} />
            )}
            <NewDashboardLayout title="Collaborators" description="Manage event collaborators">
                <section className="collaborators_page">
                    <div className="top_row">
                        <h4>Collaborators</h4>
                        <div className="button_row">
                            <button className="invite_collaborator" type="button" onClick={inviteCollaborator}>
                                <PlusIcon fill="#fff" />
                                Invite
                            </button>
                        </div>
                    </div>
                    {status === "loading" ? (
                        <Spinner />
                    ) : (
                        <div className="content">
                            {activeEventData &&
                            activeEventData?.collaborators &&
                            Array.isArray(activeEventData.collaborators) &&
                            activeEventData.collaborators.length > 0 ? (
                                    <table>
                                        <thead>
                                            <th>
                                                <input type="checkbox" />
                                            </th>
                                            <th>Name</th>
                                            <th>Role</th>
                                            <th>Permission</th>
                                            <th>Status</th>
                                            {/* <th></th> */}
                                        </thead>
                                        <tbody>
                                            {activeEventData &&
                                            Array.isArray(activeEventData.collaborators) &&
                                            activeEventData.collaborators.length > 0 &&
                                            activeEventData.collaborators.map((collaborator, index) => {
                                                const permission = collaborator?.permission
                                                    ? collaborator?.permission === "view_only"
                                                        ? "View only"
                                                        : collaborator?.permission === "can_edit"
                                                            ? "Can Edit"
                                                            : collaborator?.permission
                                                    : "--";

                                                const collaboratorPermission = collaborator?.role === "Event Owner" ? "Admin" : permission;
                                                const planStatus = collaborator?.plan_status === "ACTIVE" ? "Active" : collaborator?.plan_status;
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <input type="checkbox" />
                                                        </td>
                                                        <td>
                                                            {collaborator?.name ? collaborator?.name : "--"}
                                                            <br /> <p>{collaborator?.email}</p>
                                                        </td>
                                                        <td>
                                                            <div className="permission">
                                                                {collaborator.role} <InfoIconFill />
                                                            </div>
                                                        </td>
                                                        <td>{collaboratorPermission}</td>
                                                        <td>{planStatus}</td>
                                                        {/* <td>
                                                            <SiteDropdown className="custom" title={<ThreeDotsIcon />} position={dropdownAlignment}>
                                                                <button onClick={() => toast.info("Feature in progress")}>Remove Collaborator</button>
                                                                <button onClick={() => editCollaboratorModal(collaborator)}>Edit Access</button>
                                                            </SiteDropdown>
                                                        </td> */}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                ) : (
                                    <DefaultView
                                        title="Collaborator"
                                        description="Manage event collaborators"
                                        buttonText="Invite Collaborator"
                                        onClickHandler={inviteCollaborator}
                                    />
                                )}
                        </div>
                    )}
                </section>
            </NewDashboardLayout>
        </>
    );
}
